import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import { useMediaQuery } from '../../helpers/media';
import Img from 'gatsby-image';

import 'swiper/css';
import 'swiper/css/effect-fade';

import '../../styles/components/cursor.scss';

import SwiperCore, {
  Keyboard,
  Navigation,
  Pagination,
  Autoplay
} from 'swiper';

let isTouch;

SwiperCore.use([Autoplay, Keyboard, Navigation, Pagination]);

export default function Images( props ) {
		useEffect(() => {
				isTouch 	= /Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  	});

		let currentIndex = 0;

	return (
		<div 
			className={ 'homepage-images' }
		>
			<Swiper 
				loop="true"
				speed={0}
				spaceBetween={13}
				autoplay={{
					enabled: isTouch ? true : false,
					delay: isTouch ? 4500 : 0
				}}
				keyboard={{
					enabled: true
				}}
				simulateTouch={false}
				onInit={(swiper) => {
					let slides = swiper.slides.length - 3;
					// console.log(slides);
					let slide = Math.floor(Math.random() * (slides - 0 + 1) + 0);
					console.log(slide);
					currentIndex = slide;
					let cursor = document.querySelector( '.cursor-container' );
					let cursorText = document.querySelector( '.cursor-text' );
					swiper.slideTo(currentIndex);
					function throttle(fn, threshhold, scope) {
					  threshhold || (threshhold = 145);
					  var last,
					      deferTimer;
					  return function () {
					    var context = scope || this;

					    var now = +new Date,
					        args = arguments;
					    if (last && now < last + threshhold) {
					      // hold on to it
					      clearTimeout(deferTimer);
					      deferTimer = setTimeout(function () {
					        last = now;
					        fn.apply(context, args);
					      }, threshhold);
					    } else {
					      last = now;
					      fn.apply(context, args);
					    }
					  };
					}

					const updateSlide = (e) => {
							if( currentIndex === slides ) {
								currentIndex = 0;
							} else {
								currentIndex++;
							}
							swiper.slideTo(currentIndex);
							
							let currentSlide = swiper.slides[currentIndex];
							let currentCaption = currentSlide.querySelector( 'figcaption' );
							cursorText.innerHTML = '';
							cursorText.innerHTML = currentCaption.innerHTML;

					}

					const updateCursor = (e) => {
						cursor.style.left = e.clientX + "px";
						cursor.style.top = e.clientY + "px";

						if( !e.target.dataset.excludeCursor ) {
							cursorText.style.opacity = 1;
						} else {
							cursorText.style.opacity = 0;
						}
					}

					document.body.addEventListener( 'mousemove', throttle( updateSlide ));
					document.body.addEventListener( 'mousemove', updateCursor);


				}}
			>
			{ props.media.homepageImages.map((slide) => (
				<SwiperSlide className={ 'homepage-images__slide' }>
					
					{ slide.fieldGroupName === 'Page_Homepageimages_HomepageImages_OneImage' && (
						<div className={ 'homepage-images__one' }>
							<figure>
								{ slide.type === 'image' && (
									<>
									{ slide.image.localFile.extension !== 'gif' && (
										<>
											<Img 
												style={{
													height: '100%'
												}}
												imgStyle={{ 
													objectFit: 'contain', 
													height: '100%',
												}} 
												loading="eager"
												fadeIn={ false }
												fluid={ slide.image.localFile.childImageSharp.fluid } 
											/>
										</>
									)}
									{ slide.image.localFile.extension === 'gif' && (
										<img style={{ height: '100vh', objectFit: 'contain', height: '100%' }} src={ slide.image.localFile.publicURL } />
									)}
									</>
								)}

								{ slide.type === 'video' && (
									<video autoPlay playsInline loop muted>
										<source src={ slide.video.localFile.publicURL } />
									</video>
								)}
							</figure>
							<figcaption dangerouslySetInnerHTML={{ __html: slide.caption }} />
						</div>
					)}

					{ slide.fieldGroupName === 'Page_Homepageimages_HomepageImages_TwoImages' && (
						<div className={ 'homepage-images__two' }>
							<div className={ 'homepage-images__multi' }>
								<figure>
									{ slide.typeOne === 'image' && (
										<>
										{ slide.imageOne.localFile.extension !== 'gif' && (
											<>
												<Img 
													style={{
														height: '100%'
													}}
													imgStyle={{ 
														display: "block",
														margin: "0 0 0 auto",
														width: "auto",
														objectFit: 'contain', 
														height: '100%',
													}} 
													loading="eager"
													fadeIn={ false }
													fluid={ slide.imageOne.localFile.childImageSharp.fluid } 
												/>
											</>
										)}

										{ slide.imageOne.localFile.extension === 'gif' && (
											<img style={{ height: '100vh', objectFit: 'contain', height: '100%' }} src={ slide.imageOne.localFile.publicURL } />
										)}
										</>
									)}

									{ slide.typeOne === 'video' && (
										<video autoPlay playsInline loop muted>
											<source src={ slide.videoOne.localFile.publicURL } />
										</video>
									)}
								</figure>
								<figure>
									{ slide.typeTwo === 'image' && (
										<>
										{ slide.imageTwo.localFile.extension !== 'gif' && (
											<>
												<Img 
													style={{
														height: '100%'
													}}
													imgStyle={{ 
														display: "block",
														margin: "0 auto 0 0",
														width: "auto",
														objectFit: 'contain', 
														height: '100%',
													}} 
													loading="eager"
													fadeIn={ false }
													fluid={ slide.imageTwo.localFile.childImageSharp.fluid } 
												/>
											</>
										)}

										{ slide.imageTwo.localFile.extension === 'gif' && (
											<img style={{ height: '100vh', objectFit: 'contain', height: '100%' }} src={ slide.imageTwo.localFile.publicURL } />
										)}
										</>
									)}

									{ slide.typeTwo === 'video' && (
										<video autoPlay playsInline loop muted>
											<source src={ slide.videoTwo.localFile.publicURL } />
										</video>
									)}
								</figure>
								<figcaption dangerouslySetInnerHTML={{ __html: slide.caption }} />
							</div>
						</div>
					)}

					{ slide.fieldGroupName === 'Page_Homepageimages_HomepageImages_ThreeImages' && (
						<div className={ 'homepage-images__three' }>
							<div className={ 'homepage-images__multi' }>
								<figure>
										{console.log(slide.imageOne)}

									{ slide.typeOne === 'image' && (
										<>
										{ slide.imageOne.localFile.extension !== 'gif' && (
											<>
												<Img 
													style={{
														height: '100%'
													}}
													imgStyle={{ 
														display: "block",
														margin: "0 0 0 auto",
														width: "100%",
														objectFit: 'cover', 
														height: '100%',
													}} 
													loading="eager"
													fadeIn={ false }
													fluid={ slide.imageOne.localFile.childImageSharp.fluid } 
												/>
											</>
										)}
										{ slide.imageOne.localFile.extension === 'gif' && (
											<img style={{ height: '100vh', objectFit: 'contain', height: '100%' }} src={ slide.imageOne.localFile.publicURL } />
										)}
										</>
									)}

									{ slide.typeOne === 'video' && (
										<video autoPlay playsInline loop muted>
											<source src={ slide.videoOne.localFile.publicURL } />
										</video>
									)}
								</figure>

								<figure>
									{ slide.typeTwo === 'image' && (
										<>
										{ slide.imageTwo.localFile.extension !== 'gif' && (
											<>
												<Img 
													style={{
														height: '100%'
													}}
													imgStyle={{ 
														display: "block",
														margin: "0 0 0 0",
														width: "100%",
														objectFit: 'cover', 
														height: '100%',
													}} 
													loading="eager"
													fadeIn={ false }
													fluid={ slide.imageTwo.localFile.childImageSharp.fluid } 
												/>
											</>
										)}
										{ slide.imageTwo.localFile.extension === 'gif' && (
											<img style={{ height: '100vh', objectFit: 'contain', height: '100%' }} src={ slide.imageTwo.localFile.publicURL } />
										)}
										</>
									)}

									{ slide.typeTwo === 'video' && (
										<video autoPlay playsInline loop muted>
											<source src={ slide.videoTwo.localFile.publicURL } />
										</video>
									)}
								</figure>

								<figure>
									{ slide.typeThree === 'image' && (
										<>
										{ slide.imageThree.localFile.extension !== 'gif' && (
											<>
												<Img 
													style={{
														height: '100%'
													}}
													imgStyle={{ 
														display: "block",
														margin: "0 auto 0 0",
														width: "100%",
														objectFit: 'cover', 
														height: '100%',
													}} 
													loading="eager"
													fadeIn={ false }
													fluid={ slide.imageThree.localFile.childImageSharp.fluid } 
												/>
											</>
										)}
										{ slide.imageThree.localFile.extension === 'gif' && (
											<img style={{ height: '100vh', objectFit: 'contain', height: '100%' }} src={ slide.imageThree.localFile.publicURL } />
										)}
										</>

									)}

									{ slide.typeThree === 'video' && (
										<video autoPlay playsInline loop muted>
											<source src={ slide.videoThree.localFile.publicURL } />
										</video>
									)}
								</figure>
								<figcaption dangerouslySetInnerHTML={{ __html: slide.caption }} />
							</div>
						</div>
					)}

				</SwiperSlide>
			)) }
			</Swiper>
			<div className={ 'cursor-container' }>
				<p className={ 'cursor-text' }>Cursor</p>
			</div>
		</div>
	);

}